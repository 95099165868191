<template>
    <div class="container">
        <Pbanner :pbanner="pbanner"/>
        <div class="leval-menu" v-if="detailData.catinfo">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/companynews/companynews">新闻资讯</router-link>
                <b>></b>
                <a href="javascript:;">{{detailData.catinfo.name}}</a>
            </div>
            </div>  
        </div>
        <div class="view-box">
            <div class="view-content" v-if="detailData.info">
                <div class="view-info">
                    <div class="view-top">
                        <strong>{{detailData.info.title}}</strong>
                        <div class="unit" v-if="detailData.info.company !=''">单位：{{detailData.info.company}}</div>
                        <div class="views-time">
                            <p><i class="iconfont">&#xe601;</i>发布日期：{{detailData.info.create_time}}</p>
                            <!-- <p><i class="iconfont">&#xe62c;</i>访问量：{{detailData.info.hits}}</p> -->
                        </div>
                        
                    </div>
                    <div class="views-text" v-html="detailData.info.content"></div>
                </div>
                <div class="view-menu" v-if="detailData.info">
                    <share :config="config"></share>
                    <div class="view-backs">
                        <span class="iconfont" @click="handPrevpage(detailData.info.pre_cid)">&#xe608;</span>
                        <span class="iconfont" @click="handBack()">&#xe620;</span>
                        <span class="iconfont" @click="handPrevpage(detailData.info.next_cid)">&#xe607;</span>
                    </div>
                </div>
            </div>
            <div class="view-arlticle">
                <div class="nextPages" v-if="nextInfo.info">
                    <strong>下一条</strong>
                    <a v-if="nextInfo.info.next_cid != '#'" href="javascript:;" @click="handPrevpage(detailData.info.next_cid)">
                        <time><i class="iconfont">&#xe601;</i>发布日期：{{nextInfo.info.create_time}}</time>
                        <p>{{nextInfo.info.title}}</p>
                    </a>
                    <a v-else>
                        <p>没有了</p>
                    </a>
                </div>
                <!-- <div class="recommend-news">
                    <div class="recommend-title">
                        <strong>推荐新闻</strong>
                        <router-link to="/companynews">全部</router-link>
                    </div>
                    <ul class="recommend-list" v-if="recommendNens">
                        <li v-for="item in recommendNens.slice(0,3)" :key="item.cid">
                            <router-link :to="'/companynews/'+item.cid">
                                <strong>{{item.title}}</strong>
                                <time>{{item.create_time}}</time>
                            </router-link>
                        </li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default { 
    data(){
        return {
            pbanner:"",
            menuNumber: 3,
            detailData: [],
            nextDetail: null,
            nextInfo:[],
            recommendNens:[],
            config:{
                sites: ['weibo','wechat'],
                wechatQrcodeTitle   : '微信扫一扫：分享',
                wechatQrcodeHelper  : '<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>'
            }
        }
    }, 
    components:{
      Pbanner,
      Levalmenu
    },
    methods: {
        async getNewsDetail(){
            let data = this.$route.params.cid;
            var qs = require('qs');
            const {data: res} = await this.$http.post("getNewsDetail",qs.stringify({ 'cid': data }));
            if(res.status == 1){
                this.detailData = res.data;
                this.pbanner = this.detailData.catinfo.thumb;
                this.nextDetail = this.detailData.info.next_cid;
                const {data: res1} = await this.$http.post("getNewsDetail",qs.stringify({ 'cid': this.nextDetail }));
                if(res1.status == 1){
                    this.nextInfo = res1.data;
                    console.log(this.nextInfo)
                }else{
                    this.$message.error('数据请求失败');
                } 
            }else{
                this.$message.error('数据请求失败');
            }
        },
        async getRecommend(){
            const {data: res} = await this.$http.post("getNews");
            if(res.status == 1){
                this.recommendNens = res.data.newlist;
            }else{
                this.$message.error('数据请求失败');
            }
        },
        handPrevpage(cid){
            if(cid !== "#"){
                this.$router.push({path:`/companynews/${cid}`})
                this.getNewsDetail();
            }else{
                this.$message.error('没有了');
            }
        },
        handBack(){
            this.$router.go(-1);
            this.getNewsDetail();
        }
    },
    mounted() {
        this.getNewsDetail();
        this.getRecommend();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },
    watch: {
        $route(to, from) {
            this.getNewsDetail();
            this.getRecommend();
        }
    }
}
</script>

<style lang="less">
    .view-box{
        padding: 76px 0 66px;
        width: 950px;
        margin: auto;
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
    }
    .view-content{
        position: relative;
        box-sizing: border-box;
        // padding-left: 120px;
    }
    .view-info{
        min-height: 350px;
    }
    .view-top{
        margin-bottom: 40px;
        text-align: center;
        strong{
            display: block;
            line-height: 1.2;
            color: #333333;
            font-size: 32px;
            text-align: center;
        }
    }
    .views-time{
        margin-top: 34px;
        display: flex;
        justify-content: center;
        p{
            margin-right: 12px;
            color: #999999;
            font-size: 16px;
            line-height: 1.2;
            i{
                display: inline-block;
                vertical-align: middle;
                position: relative;
                top: -1px;
                margin-right: 8px;
                color: #999999;
                font-size: 20px;
                font-style: normal;
            }
        }
    }
    .views-text{
        color: #333333;
        font-size: 16px !important;
        line-height: 2.2 !important;
        text-align: justify;
        p,span{
            font-size: 16px !important;
        line-height: 2.2 !important;
        }
        img{
            max-width: 100% !important;
            width: auto !important;
            height: auto;
            display: block;
            margin: auto;
        }
    }
    .view-menu{
        position: absolute;
        right: -130px;
        top: 0;
        width: 58px;
        background: #f7f7f7;
        .social-share{
            .social-share-icon{
                margin: 0;
                display: block;
                width: 100%;
                border: none;
                border-radius: 0px;
                height: 66px;
                border-bottom: 1px solid #ebebeb;
                &:before{
                    position: absolute;
                    left: 50%;
                    margin-left: -12.5px;
                    top: 50%;
                    margin-top: -10px;
                    content: "";
                    width: 25px;
                    height: 20px;
                    transition: 0.36s;
                }
                &.icon-weibo{
                    &:before{
                        background: url(../../assets/sina.png) center no-repeat;
                    }
                    &:hover{
                        background: none;
                        &:before{
                            background: url(../../assets/sina1.png) center no-repeat;
                        }
                    }
                }
                &.icon-wechat{
                    &:before{
                        background: url(../../assets/wx.png) center no-repeat;
                    }
                    &:hover{
                        background: none;
                        &:before{
                            background: url(../../assets/wx1.png) center no-repeat;
                        }
                    }
                }
            }
        }
    }
    .view-menu .social-share .icon-wechat .wechat-qrcode{
        left: 50%;
        margin-left: -101px;
    }
    .view-backs{
        span{
            display: block;
            cursor: pointer;
            height: 66px;
            line-height: 66px;
            text-align: center;
            color: #a2a2a2;
            font-size: 22px;
            background: #f7f7f7;
            border-bottom: 1px solid #ebebeb;
            transition: 0.4s;
            &:hover{
                color: #c4261d;
            }
        }
    }
    .view-arlticle{
        margin-left: 0 !important;
        margin-top: 40px;
        width: auto;
    }
    .nextPages{
        padding: 32px 27px 28px;
        border: 1px solid #ebebeb;
        strong{
            display: block;
            position: relative;
            color: #767676;
            font-size: 22px;
            font-weight: normal;
            line-height: 1;
            &:before{
                content: "";
                position: absolute;
                left: 86px;
                right: 0;
                height: 1px;
                top: 50%;
                background: #767676;
            }
        }
        a{
            display: block;
            margin-top: 20px;
            &:hover{
                p{
                    color: #c4261d;
                }
            }
            time{
                display: block;
                margin-bottom: 25px;
                line-height: 1;
                color: #999999;
                font-size: 16px;
                i{
                    display: inline-block;
                    margin-right: 9px;
                    vertical-align: middle;
                    position: relative;
                    top: -1px;
                    color: #999999;
                    font-size: 20px;
                    font-style: normal;
                }
            }
            p{
                color: #666666;
                font-size: 16px;
                line-height: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient: vertical;
                transition: 0.36s;
            }
        }
    }
    .recommend-news{
        margin-top: 32px;
        padding: 30px 0 0;
        background: #fafafa;
    }
    .recommend-title{
        display: flex;
        padding: 0 32px 0 28px;
        justify-content: space-between;
        align-items: center;
        strong{
            display: block;
            line-height: 1;
            color: #666666;
            font-size: 22px;
            font-weight: normal;
        }
        a{
            display: inline-block;
            color: #999999;
            font-size: 16px;
            line-height: 1;
            &:hover{
                color: #c4261d;
            }
        }
    }
    .recommend-list{
        li{
            border-bottom: 1px solid #ebebeb;
            a{
                display: block;
                padding: 26px 24px 28px;
                &:hover{
                    strong{
                        color: #c4261d;
                    }
                }
                strong{
                    display: block;
                    margin-bottom: 15px;
                    line-height: 1.8;
                    color: #666666;
                    font-size: 16px;
                    font-weight: normal;
                    transition: 0.38s;
                }
                time{
                    display: block;
                    line-height: 1;
                    color: #999999;
                    font-size: 16px;
                }
            }
        }
    }
</style>