<template>
  <div class="container">
      <transition name="fade">
        <router-view></router-view>
      </transition>
      <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
export default {
    components: {
        Footer,
    },
}
</script>

<style>

</style>