<template>
   <ul class="leval-menu-list">
        <li :class="index==active?'active':''" v-for="(item,index) in menuLeval" :key="item.id" @click="classToggle(index)" >
            <router-link v-if="$route.path != '/about/party/' && $route.path != '/about/partyact/' && $route.path != '/about/party' && $route.path != '/about/partyact'" active-class="active" :to="`${fristmenu}${item.path}`" >{{item.name}}</router-link>
            <router-link v-else active-class="active" :to="`${fristmenu}${item.url}`" >{{item.name}}</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        menuNumber: Number,
    },
    data() {
        return {
            fristmenu:[],
            menuLeval: [],
            active: 0
        }
    },
    methods: {
        async getNavs(){
            const {data: res} = await this.$http.post("getCate");
            this.fristmenu = res.data[this.menuNumber].path;
           
            if(this.$route.path == '/about/party/' || this.$route.path == '/about/partyact/' || this.$route.path == '/about/party' || this.$route.path == '/about/partyact'){
               let arr=res.data[this.menuNumber].subItems
            //    console.log(arr)
            console.log(arr)
               arr.forEach((element, i) => {
                    if(element.id == 10){
                        this.menuLeval = element.child
                    }
                });
            }else{
                this.menuLeval = res.data[this.menuNumber].subItems;
            }
        },
        classToggle(index){
          this.active = index;
        }
    },
    mounted() {
        this.getNavs();
    }
}
</script>

<style lang="less">
    .leval-menu{
        height: 56px;
        background: #c4261d;
    }
    .leval-menu-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .leval-menu-list{
        display: flex;
        align-items: center;
        li{
            margin-right: 60px;
            &:last-child{
                margin-right: 0;
            }
            a:hover,a.active{
                &:before{
                    width: 100%;
                    right: auto;
                    left: 0;
                }
            }
            a{
                display: inline-block;
                position: relative;
                height: 56px;
                line-height: 56px;
                color: #fff;
                font-size: 16px;
                &:before{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    content: "";
                    width: 0;
                    height: 2px;
                    background: #fff;
                    transition: 0.45s;
                }
            }
        }
    }
    .posites{
        display: flex;
        align-items: center;
        span,p,a,b{
            display: inline-block;
            color: #fff;
            font-size: 14px;
        }
        b{
            margin: 0 6px;
            font-weight: normal;
        }
        a{
            position: relative;
            &:hover{
                &:before{
                    width: 100%;
                    right: auto;
                    left: 0;
                }
            }
            &:before{
                position: absolute;
                right: 0;
                bottom: 0;
                content: "";
                width: 0;
                height: 1px;
                background: #fff;
                transition: 0.5s;
            }
        }
    }
</style>